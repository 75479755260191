import { Link } from 'gatsby'
import TelegramIcon from './icons/telegram'
import TentIcon from 'mdi-react/QqchatIcon'
import WechatIcon from 'mdi-react/WechatIcon'
import * as React from 'react'

interface FooterProps {
    minimal?: boolean
    className?: string
}

export const Footer: React.FunctionComponent<FooterProps> = ({ minimal, className }) => (
    <footer className={`${minimal ? '' : 'pt-6 pb-2'} ${className || ''}`}>
        <div className="footer__container container">
            {!minimal && (
                <React.Fragment>
                    <div className="row footer__nav-sections">
                        <div className="col-sm-6 col-md-3 col-lg-2 mt-3 mb-3 order-md-2">
                            <h3 className="footer__nav-header">服务与支持</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/docs">文档</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pricing">价格</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/blog">博客</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-2 mt-3 mb-3 order-md-3">
                            <h3 className="footer__nav-header">更多功能</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/docs">浏览器插件</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/docs">IDE扩展</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/docs">代码仓库集成</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-2 mt-3 mb-3 order-md-4">
                            <h3 className="footer__nav-header">关于CodeIN</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/about">关于我们</Link>
                                </li>
                                <li className="nav-item">
                                    <a href="/jobs">加入我们</a>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact">联系我们</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-3 mb-5 order-md-1">
                            <Link className="row ml-1" to="/">
                                <img alt="CodeIN - 智能代码搜索引擎" src="/codein-logo.svg" />
                            </Link>
                            <ul className="nav footer__social mt-1">
                                <li className="nav-item">
                                    <a
                                        href="https://t.me/codeindev"
                                        target="_blank"
                                        rel="nofollow noopener"
                                        aria-label="Telegram"
                                    >
                                        <TelegramIcon width={20} height={20} />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/concact" rel="nofollow noopener" aria-label="LinkedIn">
                                        <WechatIcon />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/concact" rel="nofollow noopener" aria-label="Twitter">
                                        <TentIcon />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </React.Fragment>
            )}
            <div className="footer__postscript d-flex justify-content-between pt-4 pb-2 small">
                <ul className="nav">
                    <li className="nav-item text-muted mr-3">&copy; 2022 CodeIN</li>
                    <li className="nav-item">
                        <Link to="/terms" className="nav-link">
                            服务条款
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/privacy" className="nav-link">
                            隐私政策
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/security" className="nav-link">
                            安全说明
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
)
