import { Link } from 'gatsby'
import * as React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'

interface HeaderProps {
    minimal?: boolean
    className?: string
}

export default class Header extends React.Component<HeaderProps, any> {
    constructor(props: HeaderProps) {
        super(props)

        this.dropdownToggle = this.dropdownToggle.bind(this)
        this.toggle = this.toggle.bind(this)
        this.productMenuToggle = this.productMenuToggle.bind(this)
        this.resourcesMenuToggle = this.resourcesMenuToggle.bind(this)
        this.customerMenuToggle = this.customerMenuToggle.bind(this)

        this.state = {
            isOpen: false,
            menuOpen: false,
            productMenuOpen: false,
            resourcesMenuOpen: false,
            customerMenuOpen: false,
        }
    }

    public dropdownToggle(newValue: boolean) {
        this.setState({ menuOpen: newValue })
    }

    public toggle(): void {
        this.setState({ isOpen: !this.state.isOpen })
    }

    public productMenuToggle() {
        this.setState({ productMenuOpen: !this.state.productMenuOpen })
    }

    public resourcesMenuToggle() {
        this.setState({ resourcesMenuOpen: !this.state.resourcesMenuOpen })
    }

    public customerMenuToggle() {
        this.setState({ customerMenuOpen: !this.state.customerMenuOpen })
    }

    public render(): JSX.Element | null {
        return (
            <>
                <nav className={`header navbar py-3 ${this.props.className || 'navbar-light'}`}>
                    <div className="container-lg">
                        <Navbar.Brand href="/">
                            <img alt="CodeIN - 智能代码搜索引擎" className="header__logo" src="/codein-logo.svg" />
                        </Navbar.Brand>

                        {!this.props.minimal && (
                            <>
                                <button
                                    className="navbar-toggler justify-content-end"
                                    data-toggle="collapse"
                                    data-target="#mobile-navbar"
                                    onClick={this.toggle}
                                >
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="navbar-toggler-icon" />
                                </button>
                                <Nav className="left-nav me-auto ml-md-2">
                                    <NavDropdown onToggle={val => this.dropdownToggle(val)} title="功能" id="Product">
                                        <NavDropdown.Item href="/code-search">代码搜索</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown id="Customers" title="帮助" onToggle={this.dropdownToggle}>
                                        <NavDropdown.Item href="/cloud">公有云</NavDropdown.Item>
                                        <NavDropdown.Item href="/enterprise">企业部署</NavDropdown.Item>
                                        <NavDropdown.Item href="/how-to-choice">如何选择?</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link href="/blog">博客</Nav.Link>
                                    <Nav.Link href="/pricing">价格</Nav.Link>
                                    <Nav.Link href="/docs">文档</Nav.Link>
                                </Nav>
                                <Nav className="right-nav justify-content-lg-end">
                                    <Nav.Link
                                        className="btn btn-outline-primary ml-3 px-5 py-2"
                                        href="https://codein.dev/search"
                                        target="_blank"
                                        title="试用CodeIN"
                                    >
                                        试用CodeIN
                                    </Nav.Link>
                                </Nav>

                                {/* Mobile Navbar */}
                                <div
                                    id="mobile-navbar"
                                    className={`collapse navbar-collapse ${this.state.isOpen ? 'show' : 'hide'}`}
                                >
                                    <ul className="nav navbar-nav">
                                        <li className="header__nav-item nav-item" role="presentation">
                                            <span
                                                className="nav-link navbar-toggle collapsed"
                                                data-toggle="collapse"
                                                data-target="#product-menu"
                                                aria-expanded="false"
                                                onClick={this.productMenuToggle}
                                            >
                                                功能
                                                <ChevronDownIcon className="icon-inline ml-1" />
                                            </span>
                                            <ul
                                                id="product-menu"
                                                className={`small-menu collapse navbar-collapse ${
                                                    this.state.productMenuOpen ? 'show' : 'hide'
                                                }`}
                                            >
                                                <li className="nav-link" role="presentation">
                                                    <a href="/code-search">代码搜索</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <span
                                                className="nav-link navbar-toggle collapsed"
                                                data-toggle="collapse"
                                                data-target="#customer-menu"
                                                aria-expanded="false"
                                                onClick={this.customerMenuToggle}
                                            >
                                                帮助
                                                <ChevronDownIcon className="icon-inline ml-1" />
                                            </span>
                                            <ul
                                                id="customer-menu"
                                                className={`small-menu collapse navbar-collapse ${
                                                    this.state.customerMenuOpen ? 'show' : 'hide'
                                                }`}
                                            >
                                                <li className="nav-link" role="presentation">
                                                    <a href="/case-studies">公有云</a>
                                                </li>
                                                <li className="nav-link" role="presentation">
                                                    <a href="/customers">企业部署</a>
                                                </li>
                                                <li className="nav-link" role="presentation">
                                                    <a href="/how-to-choice">如何选择?</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" href="/blog">
                                                博客
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <Link
                                                className="nav-link"
                                                to="/pricing"
                                                activeClassName="header__nav-link-active"
                                            >
                                                价格
                                            </Link>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" href="/docs" target="_blank">
                                                文档
                                            </a>
                                        </li>
                                        <li className="header__nav-item nav-item" role="presentation">
                                            <a className="nav-link" target="_blank" href="https://codein.dev/search">
                                                试用CodeIN
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                </nav>
            </>
        )
    }
}
